import React , { useState } from 'react';

import capacity from '@assets/images/capacity.webp';

const Caculator = ({ additinalClass , title , liters }) => {

    const [calculate, setCalculate] = useState(0);
    const [sqft, setSqft] = useState('');
    const [error, setError] = useState('');

    const onCalculate = () => {
        setError('');

        if( sqft ){
            var newLiters = parseFloat(sqft / liters).toFixed(2);
            setCalculate(newLiters);
        } else{
            setCalculate(0);
            setError('Please enter the sqft amount');
        }
    }

    return(
        <div className={`calullator ${additinalClass}`}>
            <div className="contWrap">
                <h4>{title}</h4>
                <div className="formWrap">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <img src={capacity} alt="capacity" width="23" height="22" />
                            </span>
                        </div>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Enter the sqft amount"
                            onChange={(e) => setSqft(e.target.value.replace(/[^0-9]/g, ''))}
                            value={sqft}
                            pattern="[0-9]*"
                        />
                    </div>
                    <button 
                        className="btn calculateBtn"
                        onClick={() => onCalculate()}
                    >Calculate</button>
                </div>

                <div className="litersWrap">
                    {
                        (calculate) ?
                        <span>You need <span className='liters'>{calculate}</span> liters of paint per coat</span>
                        : ''
                    }
                    {
                        error && <span>{error}</span>
                    }
                </div>
            </div>
        </div>
    )
}

export default Caculator;
