import { createSelector } from 'reselect';

const selectUserAPI = state => state.user;

export const selectIsUserLoggedIn = createSelector(
    [selectUserAPI],
    (user) => user.isUserLoggedIn
);

export const selectUser = createSelector(
    [selectUserAPI],
    (user) => user.user
);

export const selectShippingAddress = createSelector(
    [selectUserAPI],
    (user) => user.shippingAddress
);

export const selectEmailToCart = createSelector(
    [selectUserAPI],
    (user) => user.emailToCart
);

export const selectBillingAddress = createSelector(
    [selectUserAPI],
    (user) => user.billingAddress
);

export const selectOrderID = createSelector(
    [selectUserAPI],
    (user) => user.orderID
);

export const selectUserRegistration = createSelector(
    [selectUserAPI],
    (user) => user.userRegistration
);

export const selectInvoiceData = createSelector(
    [selectUserAPI],
    (user) => user.invoiceData
);

export const selectUserConfirmed = createSelector(
    [selectUserAPI],
    (user) => user.userConfirmed
);