import React from 'react';
import {scroller} from "react-scroll";
import {useNavigate , useLocation} from "react-router-dom";
import { isMobile } from 'react-device-detect';

const Dropdown = (props) => {

    const history = useLocation();
    const nav = useNavigate();
    const scrollTarget = (target) => scroller.scrollTo(target, {smooth: true, offset: isMobile ? -400 : -122 });

    const scrollToPage = async (target) => {
        if (history.pathname !=='/') {
            await nav('/');
        }
        scrollTarget(target);
    };

    return (
        <div className="dropdown-menu productsDropdown">
            <span 
                className="dropdown-item" 
                onClick={() => { 
                    props.setdropDownToggle(!props.dropDownToggle); 
                    scrollToPage('intProductActionWrap'); 
                    props.setToggleMenu(false);
                }}
            >Interior and Exterior Emulsion</span>
            <span
                className="dropdown-item" 
                onClick={() => { 
                    props.setdropDownToggle(!props.dropDownToggle); 
                    scrollToPage('extProductActionWrap'); 
                    props.setToggleMenu(false);
                }}
            >Wall Filler and Sealer</span>
        </div>
    )
}

export default Dropdown;