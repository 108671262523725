import ls from 'localstorage-slim';

import { commonActionTypes } from './common.types';

const storageCartToken = ls.get('cartToken') || null;

const INITIAL_STATE = {
    cartCount: 0,
    cartPrice: '0.00',
    cart: [],
    cartToken : storageCartToken,
    availableShippingMethods: [],
    cartLoading: true
}

const commonReducer = ( state = INITIAL_STATE , action ) => {
    switch (action.type) {
        case commonActionTypes.SET_CART_COUNT:
            return{
                ...state , 
                cartCount : action.payload
            }
        case commonActionTypes.SET_CART:
            return{
                ...state , 
                cart : action.payload
            }
        case commonActionTypes.SET_CART_PRICE:
            return{
                ...state , 
                cartPrice : action.payload
            }
        case commonActionTypes.SET_CART_TOKEN:
            return{
                ...state , 
                cartToken : action.payload
            }
        case commonActionTypes.SET_AVAILABLE_SHIPPING_METHOD:
            return{
                ...state , 
                availableShippingMethods : action.payload
            }
        case commonActionTypes.CART_LOADING:
            return{
                ...state , 
                cartLoading : action.payload
            }
        default:
            return state;
    }
}

export default commonReducer;