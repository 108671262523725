import { gql } from '@apollo/client';

export const CREATE_EMPTY_CART = gql`
mutation CreateEmptyCart {
  createEmptyCart
}`;

export const CREATE_CUSTOMER = gql`
mutation CreateCustomer($firstname: String! , $lastname: String! , $email: String!, $password: String!) {
    createCustomer(
        input: {
            firstname: $firstname
            lastname: $lastname
            email: $email
            password: $password
            is_subscribed: false
        }
    ) {
        customer {
            firstname
            lastname
            email
            is_subscribed
        }
    }
}`;

export const GENERATE_CUSTOMER_TOKEN = gql`
mutation GenerateCustomerToken($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      token
    }
}`;

export const CUSTOMER_CART = gql`
query CustomerCart {
    customerCart{
        id
    }
}`;

export const ADD_SIMPLE_PRODUCT_TO_CART = gql`
mutation AddSimpleProductsToCart($cartID: String!, $sku: String! , $quantity: Float! ) {
    addSimpleProductsToCart(
        input: {
            cart_id: $cartID
            cart_items: [
                {
                    data: {
                        quantity: $quantity
                        sku: $sku
                    }
                }
            ]
        }
    ) {
        cart {
            items {
                id
                product {
                    name
                    sku
                    thumbnail{
                        url
                    }
                }
                quantity
                prices {
                    row_total{
                        value
                    }
                }
            }
            prices {
                grand_total {
                    value
                }
            }
        }
    }
}`;

export const ADD_VIRTUAL_PRODUCT_TO_CART = gql`
mutation AddVirtualProductsToCart($cartID: String!, $sku: String! , $quantity: Float! ) {
    addVirtualProductsToCart(
        input: {
            cart_id: $cartID
            cart_items: [
                {
                    data: {
                        quantity: $quantity
                        sku: $sku
                    }
                }
            ]
        }
    ) {
        cart {
            items {
                id
                product {
                    name
                    sku
                    thumbnail{
                        url
                    }
                }
                quantity
                prices {
                    row_total{
                        value
                    }
                }
            }
            prices {
                grand_total {
                    value
                }
            }
        }
    }
}`;

export const SET_SHIPPING_ADDRESS_ON_CART = gql`
mutation SetShippingAddressesOnCart(
    $cartID: String!, 
    $firstname: String!, 
    $lastname: String!, 
    $company: String!, 
    $street1: String!, 
    $street2: String!, 
    $city: String!, 
    $region: String!, 
    $postcode: String!, 
    $countryCode: String!, 
    $telephone: String!,
    $additinaltel: String!
    ) {
    setShippingAddressesOnCart(
        input: {
            cart_id: $cartID
            shipping_addresses: [
                {
                    address: {
                        firstname: $firstname
                        lastname: $lastname
                        company: $company
                        street: [$street1, $street2]
                        city: $city
                        region: $region
                        postcode: $postcode
                        country_code: $countryCode
                        telephone: $telephone
                        additinaltel: $additinaltel
                        save_in_address_book: false
                    }
                }
            ]
        }
    ) {
        cart {
            shipping_addresses {
                firstname
                lastname
                company
                street
                city
                region {
                    code
                    label
                }
                postcode
                telephone
                country {
                    code
                    label
                }
                available_shipping_methods{
                    carrier_code
                    carrier_title
                    method_code
                    method_title
                    amount {
                        value
                        currency
                    }
                    price_excl_tax {
                        value
                        currency
                    }
                    price_incl_tax {
                        value
                        currency
                    }
                }
            }
        }
    }
}`;

export const SET_BILLING_ADDRESS_ON_CART = gql`
mutation SetBillingAddressOnCart(
    $cartID: String!, 
    $firstname: String!, 
    $lastname: String!, 
    $company: String!, 
    $street1: String!, 
    $street2: String!, 
    $city: String!, 
    $region: String!, 
    $postcode: String!, 
    $countryCode: String!, 
    $telephone: String!,
    $additinaltel: String!
    $sameShipping: Boolean
) {
    setBillingAddressOnCart(
        input: {
            cart_id: $cartID
            billing_address: {
                address: {
                    firstname: $firstname
                    lastname: $lastname
                    company: $company
                    street: [$street1, $street2]
                    city: $city
                    region: $region
                    postcode: $postcode
                    country_code: $countryCode
                    telephone: $telephone
                    additinaltel: $additinaltel
                    save_in_address_book: false
                }
                same_as_shipping: $sameShipping
            }
        }
    ) {
        cart {
            billing_address {
                firstname
                lastname
                company
                street
                city
                region{
                    code
                    label
                }
                postcode
                telephone
                country {
                    code
                    label
                }
            }
        }
    }
}`;

export const SET_SHIPPING_METHODS_ON_CART = gql`
mutation SetShippingMethodsOnCart(
    $cartID: String!, 
    $carrierCode: String!, 
    $methodCode: String!
) {
    setShippingMethodsOnCart(input: {
        cart_id: $cartID
        shipping_methods: [
            {
                carrier_code: $carrierCode
                method_code: $methodCode
            }
        ]
    }) {
        cart {
            shipping_addresses {
                selected_shipping_method {
                    carrier_code
                    method_code
                    carrier_title
                    method_title
                }
            }
        }
    }
}`;

export const SET_GUEST_EMAIL_ON_CART = gql`
mutation SetGuestEmailOnCart(
    $cartID: String!, 
    $email: String!
) {
    setGuestEmailOnCart(input: {
        cart_id: $cartID
        email: $email
    }) {
        cart {
            email
        }
    }
}`;

export const AVAILABLE_PAYMENT_METHODS = gql`
query AvaliablePaymentMethods(
    $cartID: String!
) {
    cart(cart_id: $cartID) {
        available_payment_methods {
            code
            title
        }
    }
}`;

export const AVAILABLE_SHIPPING_METHODS = gql`
query AvaliableShippingMethods(
    $cartID: String!
) {
    cart(cart_id: $cartID) {
    	shipping_addresses {
            available_shipping_methods {
                amount {
                    currency
                    value
                }
                available
                carrier_code
                carrier_title
                error_message
                method_code
                method_title
                price_excl_tax {
                    currency
                    value
                }
                price_incl_tax {
                    currency
                    value
                }
            }
        }
    }
}`;

export const SET_PAYMENT_METHOD_ON_CART = gql`
mutation SetPaymentMethodOnCart(
    $cartID: String!, 
    $code: String!
) {
    setPaymentMethodOnCart(input: {
        cart_id: $cartID
        payment_method: {
            code: $code
        }
    }) {
        cart {
            selected_payment_method {
                code
            }
        }
    }
}`;

export const PLACE_ORDER = gql`
mutation PlaceOrder(
    $cartID: String!
) {
    placeOrder(input: {cart_id: $cartID}) {
        order {
            order_number
        }
    }
}`;

export const GET_PRODUCT_COLLECTION = gql`
query ProductCollection(
    $productID: String!
) {
    ProductCollection(
      productID: $productID
    ) {
        productID
        productName
        size
    }
}`;

export const GET_SLIDER = gql`
query CmsBlocks {
    cmsBlocks(identifiers:"main_slider") {
        items {
            identifier
            title
            content
        }
    }
}`;

export const GET_CUSTOMER = gql`
query Customer {
    customer {
        firstname
        lastname
        email
        default_billing
        default_shipping
        addresses {
            id
            firstname
            lastname
            company
            street
            city
            region {
                region_code
                region
            }
            postcode
            telephone
            country_code
            default_billing
            default_shipping
        }
    }
}`;

export const GET_CMS_BLOCK = gql`
query CmsBlocks(
    $identifiers: [String]
) {
    cmsBlocks(identifiers:$identifiers) {
        items {
            content
        }
    }
}`;

export const REMOVE_CART_ITEM = gql`
mutation RemoveItemFromCart($cartID: String!, $cartItemID: Int!) {
    removeItemFromCart(
        input: { 
            cart_id: $cartID, 
            cart_item_id: $cartItemID 
        }
    ) {
        cart {
            items {
                id
                product {
                    name
                    sku
                    thumbnail{
                        url
                    }
                }
                quantity
                prices {
                    row_total{
                        value
                    }
                }
            }
            prices {
                grand_total {
                    value
                }
            }
        }
    }
}`;

export const GET_CMS_PAGE = gql`
query CmsPage(
    $identifiers: String
) {
    cmsPage(identifier: $identifiers) {
        title
        content
    }
}`;

export const UPDATE_CART_ITEMS = gql`
mutation UpdateCartItems($cartID: String!, $cartItemID: Int! , $quantity: Float! ) {
    updateCartItems(
        input: {
            cart_id: $cartID,
            cart_items: [
                {
                    cart_item_id: $cartItemID
                    quantity: $quantity
                }
            ]
        }
    ){
        cart {
            items {
                id
                product {
                    name
                    sku
                    thumbnail{
                        url
                    }
                }
                quantity
                prices {
                    row_total{
                        value
                    }
                }
            }
            prices {
                grand_total {
                    value
                }
            }
        }
    }
}`;

export const GET_LOCATIONS_DATA = gql`
query LocationsData {
    LocationsData {
        districts
        cities
    }
}`;

export const GET_CUSTOMER_CART = gql`
query CustomerCart {
    customerCart {
        id
        items {
            id
            product {
                name
                sku
                thumbnail {
                    url
                }
            }
            quantity
            prices {
                row_total {
                    value
                }
            }
        }
        prices {
            grand_total {
                value
            }
        }
    }
}`;

export const AUTO_CREATE_INVOICE = gql`
query AutoInvoice( $orderID: String! ) {
    AutoInvoice(
      orderID: $orderID
    ) {
        invoice
        order_id
        invoice_status
    }
}`;

export const SEND_CONTACT_QUERY = gql`
query ContactUsQuery( 
    $fname: String!,
    $contactNo: String!,
    $email: String!,
    $message: String!,
    $filesubmission: String!  
) {
    ContactUsQuery(
        fname: $fname
        contactNo: $contactNo
        email: $email
        message: $message
        filesubmission: $filesubmission
    ) {
        status
    }
}`;

export const SEND_CONTACT_MUTATION = gql`
mutation contactusFormSubmit($fname: String!, $contactNo: String!, $email: String!, $message: String!, $filesubmission: [String]){
    contactusFormSubmit(
        input: {
            fname: $fname
            contactNo: $contactNo
            email: $email
            message: $message
            filesubmission: $filesubmission
        }
    ){
        status
    }
}`;

export const GET_CUSTOMER_ORDER_HISTORY = gql`
query CustomerOrderHistory {
    customerOrders {
        items {
            order_number
            id
            created_at
            grand_total
            status
        }
    }
}`;

export const GET_SINGLE_ORDER_DETAILS = gql`
query SalesOrder($orderID: String!) {
    salesOrder (id: $orderID) {
        increment_id
        customer_name
        grand_total
        created_at
        status
        shipping_method
        payment_method
        shipping {
            name
            street
            city
            region
            country
            postcode
            telephone
            additinaltel
            fax
            company
        }
        billing {
            name
            street
            city
            region
            country
            postcode
            telephone
            additinaltel
            fax
            company
        }
        items {
            title
            sku
            price
            qty
        }
    }
}`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
mutation UpdateCustomerAddress(
    $id: Int!, 
    $firstname: String!, 
    $lastname: String!, 
    $company: String!, 
    $street: [String]!, 
    $city: String!,
    $region: String!, 
    $postcode: String!, 
    $telephone: String!,
    $additinaltel: String!,
    $defaultBilling: Boolean,
    $defaultShipping: Boolean,
) {
    updateCustomerAddress(
        id: $id
        input: {
            firstname: $firstname
            lastname: $lastname
            company: $company
            street: $street
            city: $city
            region: {
                region: $region
                region_code: $region
            }
            postcode: $postcode
            telephone: $telephone
            custom_attributes: {
                attribute_code: "additinaltel"
                value: $additinaltel
            }
            default_billing: $defaultBilling
            default_shipping: $defaultShipping
        }
    ) {
        id
        firstname
        lastname
        company
        street
        city
        region {
            region
            region_code
        }
        postcode
        telephone
    }
}`;

export const CREATE_CUSTOMER_ADDRESS = gql`
mutation CreateCustomerAddress(
    $firstname: String!, 
    $lastname: String!, 
    $company: String!, 
    $street: [String]!, 
    $city: String!,
    $region: String!, 
    $postcode: String!, 
    $telephone: String!,
    $additinaltel: String!,
    $defaultBilling: Boolean,
    $defaultShipping: Boolean,
) {
    createCustomerAddress(input: {
        firstname: $firstname
        lastname: $lastname
        company: $company
        street: $street
        city: $city
        region: {
            region: $region
            region_code: $region
        }
        postcode: $postcode
        telephone: $telephone
        custom_attributes: {
            attribute_code: "additinaltel"
            value: $additinaltel
        }
        default_billing: $defaultBilling
        default_shipping: $defaultShipping
        country_code: LK
    }) {
        id
        firstname
        lastname
        company
        street
        city
        region {
            region
            region_code
        }
        postcode
        telephone
    }
}`;

export const DELETE_CUSTOMER_ADDRESS = gql`
mutation DeleteCustomerAddress( $id: Int! ) {
    deleteCustomerAddress(
        id: $id
    )
}`;

export const UPDATE_CUSTOMER = gql`
mutation UpdateCustomer(
    $firstname: String,
    $lastname: String,
    $email: String,
    $password: String
){
    updateCustomer(
        input: {
            firstname: $firstname
            lastname: $lastname
            email: $email
            password: $password
        }
    ) {
        customer {
            firstname
            lastname
            email
            default_billing
            default_shipping
            addresses {
                id
                firstname
                lastname
                company
                street
                city
                region {
                    region_code
                    region
                }
                postcode
                telephone
                country_code
                default_billing
                default_shipping
            }
        }
    }
}`;

export const CHANGE_CUSTOMER_PASSWORD = gql`
mutation changeCustomerPassword(
    $password: String,
    $newPassword: String
){
    ChangeCustomerPassword(
        currentPassword: $password
        newPassword: $newPassword
    ) {
        id
        email
    }
}`;

export const CONFIRM_CUSTOMER_MUTATION = gql`
mutation ConfirmCustomerMutation(
    $id: String!, 
    $key: String!
){
    ConfirmCustomerMutation(
        input: {
            id: $id
            key: $key
        }
    ){
        status
    }
}`;

export const CLEAR_CART = gql`
mutation ClearCartItems(
    $ids: [String], 
    $cartID: String
){
    clearCartItems(
        input: {
            cart_id: $cartID
            cart_item_ids: $ids
        }
    ){
        status
    }
}`;

export const GET_CART_DETAILS = gql`
query GetCart(
    $cartID: String!
){
    cart(cart_id: $cartID) {
        items {
            id
            product {
                name
                sku
                thumbnail {
                    url
                }
            }
            quantity
            prices {
                row_total {
                    value
                }
            }
        }
        prices {
            grand_total {
                value
            }
        }
    }
}`;

export const CREATE_EMPTY_CART_WITH_CART_ID = gql`
mutation CreateEmptyCart(
    $cartID: String
){
    createEmptyCart(
        input: {
            cart_id: $cartID
        }
    )
}`;

export const GET_CUSTOM_VARIABLE_DATA = gql`
query CustomVariableData(
    $variableID: String!
) {
    CustomVariableData(
      variableID: $variableID
    ) {
        value
    }
}`;

export const ORDER_STATUS_CHANGE = gql`
query ChangeOrderStatus(
    $orderID: String,
    $status: String
) {
    ChangeOrderStatus(
        orderID: $orderID,
        status: $status
    ) {
        order_id
    }
}`;

export const REQUEST_PASSWORD_RESET = gql`
query RequestPasswordReset(
    $email: String
) {
    RequestPasswordReset(
        email: $email,
    ) {
        success
        message
    }
}`;

export const RESET_PASSWORD = gql`
query ResetPassword(
    $token: String!
    $password: String!
) {
    ResetPassword(
        token: $token, 
        password: $password
    ) {
        success
        message
    }
}`;