import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import interiorEmulsion from '@assets/images/int.webp';
import extEmulsionWebp from '@assets/images/ext-new-table.webp';
import acrylicInterior from '@assets/images/int-fillter.webp';
import acrylicExterior from '@assets/images/ext-filler.webp';

import application from '@assets/images/application.webp';
import capacity from '@assets/images/capacity.webp';
import sheen from '@assets/images/sheen.webp';
import time from '@assets/images/time.webp';
import dilution from '@assets/images/dilution.webp';

import TechnicalScopeMobile from './technicalScope.mobile.component';

const TechnicalScope = () => {
    return(
        <div className='technicalScopeWrap'>
            <div className='container'>
                <h2>Technical <br />Scope</h2>

                <BrowserView className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr className="paintImages">
                                <td>&nbsp;</td>
                                <td>
                                    
                                    <img src={interiorEmulsion} alt="int" loading="lazy" width="125" height="101" />
                                </td>
                                <td>
                                    <img src={extEmulsionWebp} alt="int" loading="lazy" width="125" height="101" />
                                </td>
                                <td>
                                    <img src={acrylicInterior} alt="int" loading="lazy" width="149" height="100" />
                                </td>
                                <td>
                                    <img src={acrylicExterior} alt="int" loading="lazy" width="151" height="100" />
                                </td>
                            </tr>
                            <tr className="paintNames">
                                <td>&nbsp;</td>
                                <th className="even">Interior Emulsion <br />(Brilliant White)</th>
                                <th className="even">EXTERIOR WEATHER COAT <br />(BRILLIANT WHITE)</th>
                                <th className="odd">Acrylic Interior <br />Wall Filler</th>
                                <th className="odd">ACRYLIC EXTERIOR <br />WALL SEALER</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>
                                    <p><img src={application} alt="" width="29" height="28" /> Spreading Capacity</p>
                                </th>
                                <td>Up to 21 square metres per litre / 225 square feet per litre</td>
                                <td>Up to 12 square metres per litre / 130 square feet per litre</td>
                                <td>Upto 180 ft2 / L. (May vary depending on the surface condition)</td>
                                <td>Upto 110 ft2 / L. (May vary depending on the surface condition)</td>
                            </tr>
                            <tr>
                                <th>
                                    <p><img src={capacity} alt="" width="29" height="28" /> Dilution</p>
                                </th>
                                <td>Up to 50% of water by volume of paint</td>
                                <td>Up to 20% of water by volume of paint</td>
                                <td>50% dilution (Dilute 1 part of Interior Wall Filler with 1/2 part of water by volume)</td>
                                <td>Ready to use. (If required use maximum dilution 10% )</td>
                            </tr>
                            <tr>
                                <th>
                                    <p><img src={sheen} alt="" width="29" height="28" /> Finish</p>
                                </th>
                                <td>Low sheen emulsion</td>
                                <td>Low sheen emulsion</td>
                                <td>Not applicable</td>
                                <td>Not applicable</td>
                            </tr>
                            <tr>
                                <th>
                                    <p><img src={time} alt="" width="29" height="28" /> Drying Time (Touch Dry)</p>
                                </th>
                                <td>15 mins - 30 mins</td>
                                <td>15 mins - 30 mins</td>
                                <td>30 mins</td>
                                <td>30 mins</td>
                            </tr>
                            <tr>
                                <th>
                                    <p><img src={dilution} alt="" width="29" height="28" /> Recoating Time</p>
                                </th>
                                <td>2 to 4 hours after application</td>
                                <td>2 to 3 hours after application</td>
                                <td>2-3 hours after application</td>
                                <td>2-3 hours after application</td>
                            </tr>
                        </tbody>
                    </table>
                </BrowserView>

                <MobileView className='mobileTechnicalScope'>
                    <TechnicalScopeMobile />
                </MobileView>

            </div>
        </div>
    )
}

export default TechnicalScope;
