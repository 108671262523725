import { createSelector } from 'reselect';

const selectCommonAPI = state => state.common;

export const selectCartCount = createSelector(
    [selectCommonAPI],
    (common) => common.cartCount
);

export const selectCartPrice = createSelector(
    [selectCommonAPI],
    (common) => common.cartPrice
);

export const selectCart = createSelector(
    [selectCommonAPI],
    (common) => common.cart
);

export const selectCartToken = createSelector(
    [selectCommonAPI],
    (common) => common.cartToken
);

export const selectAvailableShippingMethods = createSelector(
    [selectCommonAPI],
    (common) => common.availableShippingMethods
);

export const selectCartLoading = createSelector(
    [selectCommonAPI],
    (common) => common.cartLoading
);