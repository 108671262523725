import React , { useState } from 'react';

import interiorEmulsion from '@assets/images/int.webp';
import extEmulsionWebp from '@assets/images/ext-new-table.webp';
import acrylicInterior from '@assets/images/int-fillter.webp';
import acrylicExterior from '@assets/images/ext-filler.webp';

import InteriorEmulsionContent from './contentBlock/interior_emulsion';
import ExteriorEmulsionContent from './contentBlock/exterior_emulsion';
import AcrylicInteriorContent from './contentBlock/acrylic_interior';
import AcrylicExteriorContent from './contentBlock/acrylic_exterior';

const TechnicalScopeMobile = () => {

    const [showInt, setShowInt] = useState(false);
    const [showExt, setShowExt] = useState(false);
    const [showACRInt, setShowACRInt] = useState(false);
    const [showACRExt, setShowACRExt] = useState(false);

    const handleCardHeader = ( int , ext , acrInt , acrExt ) => {
        setShowInt(int);
        setShowExt(ext);
        setShowACRInt(acrInt);
        setShowACRExt(acrExt);
    }

    return(
        <>
            <div className='cardWrap'>
                <div 
                    className={`cardHeader ${showInt ? 'active' : ''}`}
                    onClick={() => handleCardHeader(!showInt , false , false , false)}
                >
                    <span className="imgWrap">
                        <img src={interiorEmulsion} alt="int" width="125" height="101" />
                    </span>
                    <span className="titleWrap">Interior Emulsion <br/> (Brilliant White)</span>
                </div>
                {
                    showInt ?
                    <InteriorEmulsionContent />
                    : ''
                }
            </div>

            <div className='cardWrap'>
                <div 
                    className={`cardHeader ${showExt ? 'active' : ''}`}
                    onClick={() => handleCardHeader(false , !showExt , false , false)}
                >
                    <span className="imgWrap">
                        <img src={extEmulsionWebp} alt="int" width="125" height="101" />
                    </span>
                    <span className="titleWrap">Exterior Emulsion <br/> (Brilliant White)</span>
                </div>
                {
                    showExt ?
                    <ExteriorEmulsionContent />
                    : ''
                }
            </div>

            <div className='cardWrap'>
                <div 
                    className={`cardHeader ${showACRInt ? 'active' : ''}`}
                    onClick={() => handleCardHeader(false , false , !showACRInt , false)}
                >
                    <span className="imgWrap">
                        <img src={acrylicInterior} alt="int" width="125" height="101" />
                    </span>
                    <span className="titleWrap">Acrylic Interior <br/> Wall Filler</span>
                </div>
                {
                    showACRInt ?
                    <AcrylicInteriorContent />
                    : ''
                }
            </div>

            <div className='cardWrap'>
                <div 
                    className={`cardHeader ${showACRExt ? 'active' : ''}`}
                    onClick={() => handleCardHeader(false , false , false , !showACRExt)}
                >
                    <span className="imgWrap">
                        <img src={acrylicExterior} alt="int" width="125" height="101" />
                    </span>
                    <span className="titleWrap">ACRYLIC EXTERIOR <br/> WALL SEALER</span>
                </div>
                {
                    showACRExt ?
                    <AcrylicExteriorContent />
                    : ''
                }
            </div>
        </>
    )
}

export default TechnicalScopeMobile;
