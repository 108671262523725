import React from 'react';

import PaintProductActions from '@components/paintProductActions/paintProductActions.component';
import MiniCart from '@components/miniCart/miniCart.component';

import emulsionImage from '@assets/images/int-and-ext.webp';
import sealerImage from '@assets/images/Exterior_sealer_filler-1000.webp';

const PlaceYourOrder = () => {
    return (
        <div className="productsWrap" id="productsWrap">
            <div className="container">
                <h2>Place Your Order</h2>

                <PaintProductActions 
                    productGroup="Interior and Exterior Emulsion"
                    imageUrl={emulsionImage}
                    configProduct1={process.env.REACT_APP_CONFIG_PRODUCT_1}
                    configProduct2={process.env.REACT_APP_CONFIG_PRODUCT_2}
                />

                <PaintProductActions 
                    productGroup="Wall Filler and Sealer"
                    imageUrl={sealerImage}
                    //configProduct1={process.env.REACT_APP_CONFIG_PRODUCT_3}
                    //configProduct2={process.env.REACT_APP_CONFIG_PRODUCT_4}
                    configProduct1={process.env.REACT_APP_CONFIG_PRODUCT_3}
                    configProduct2={process.env.REACT_APP_CONFIG_PRODUCT_4}
                />

                <MiniCart />

            </div>
        </div>
    )
}

export default PlaceYourOrder;
