import React from 'react';
import Slider from "react-slick";
import {isMobile} from 'react-device-detect';

import slider1 from '@assets/slider/50_-header_1.webp';
import slider2 from '@assets/slider/50_-header_2.webp';
import slider3 from '@assets/slider/50_-header_3.webp';
import mobile1 from '@assets/slider/Mobile_1.webp';
import mobile2 from '@assets/slider/Mobile_2.webp';
import mobile3 from '@assets/slider/Mobile_3.webp';

const MainSlider = () => {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true
    };

    if( isMobile ) {
        return (
            <div className='mainBannerWrap'>
                <Slider {...settings}>
                    <div className='item'>
                        <img src={mobile1} alt="mobile1" width={480} height={600} />
                    </div>
                    <div className='item'>
                        <img src={mobile2} alt="mobile2" width={480} height={600} />
                    </div>
                    <div className='item'>
                        <img src={mobile3} alt="mobile3" width={480} height={600} />
                    </div>
                </Slider>
            </div>
        )
    } else {
        return (
            <div className='mainBannerWrap'>
                <Slider {...settings}>
                    <div className='item'>
                        <img src={slider1} alt="slider1" width={1920} height={960} />
                    </div>
                    <div className='item'>
                        <img src={slider2} alt="slider2" width={1920} height={960} />
                    </div>
                    <div className='item'>
                        <img src={slider3} alt="slider3" width={1920} height={960} />
                    </div>
                </Slider>
            </div>
        )
    }
}

export default MainSlider;
