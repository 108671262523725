import React from 'react';
import { useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';

import { GET_CMS_BLOCK } from '@hooks/query';

const ExteriorEmulsionContent = () => {

    /* eslint-disable */
    const { loading, error, data } = useQuery(GET_CMS_BLOCK , {
        variables: { 'identifiers' : 'exterior_emulsion_technical_scope' },
    });

    if( loading ) {
        return (
            <>
                <br/>
                <Skeleton
                    height={100}
                />
                <br/>
                <Skeleton
                    height={100}
                />
            </>
        );
    } else{
        return(
            <div className='cardContent'>
                {
                    data ?
                    <div className='content' dangerouslySetInnerHTML={{__html: data.cmsBlocks.items[0].content }} />
                    : ''
                }
            </div>
        )
    }
}

export default ExteriorEmulsionContent;
