import React from 'react';
import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { REMOVE_CART_ITEM } from '@hooks/query';

import { selectCartToken } from '@redux/common/common.selectors';
import { setCart , setCartPrice } from '@redux/common/common.actions';

const DeleteItermSwal = withReactContent(Swal);

const MiniCartItem = ({ prices , product , quantity , id , cartToken , setCart , setCartPrice }) => {

    const [removeCartItem] = useMutation(REMOVE_CART_ITEM, { errorPolicy: 'all' });

    const handleRemoveCartItem = () => {

        DeleteItermSwal.fire({
            //title: 'Are you sure?',
            text: "Are you sure remove this item in cart",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#017f32',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.isConfirmed) {

                removeCartItem({
                    variables: {
                        cartID: String(cartToken),
                        cartItemID: id
                    },
                    onCompleted(cartData){
                        
                        if( cartData ) {
                            setCart(cartData.removeItemFromCart.cart.items);
                            setCartPrice(Number(cartData.removeItemFromCart.cart.prices.grand_total.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
                        }

                        DeleteItermSwal.fire({
                            icon: 'success',
                            title: 'Removed',
                            showConfirmButton: false,
                            timer: 1500
                        });
        
                    }
                });
            }
        });
    }

    return(
        <div className='product-item-details'>
            <div className='itemName'>{product.name}</div>
            <div className='itemPrice'>
                LKR {Number(prices.row_total.value / quantity).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} X {quantity}
            </div>
            <div className='itemAction'
                onClick={() => handleRemoveCartItem()}
            ></div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    cartToken : selectCartToken
});

const mapDispatchToProps = dispatch => ({
    setCart : (cart) => dispatch(setCart(cart)),
    setCartPrice : (cartPrice) => dispatch(setCartPrice(cartPrice)),
});

export default connect(mapStateToProps , mapDispatchToProps)(MiniCartItem);
