import React from 'react';

import ProductAddtoCart from '@components/productAddtoCart/productAddtoCart.component';

const PaintProductActions = ({ productGroup , imageUrl , configProduct1 , configProduct2 }) => {
    return (
        <div className="productActionWrap" id={`${ (productGroup === 'Interior and Exterior Emulsion') ? 'intProductActionWrap' : 'extProductActionWrap' }`}>
            <div className="proGroup"><span>{productGroup}</span></div>
            <div className="imgWrap">
                <img src={imageUrl} alt="interior" loading="lazy" width="200" height="200" />
            </div>
            <div className="productsWrapper">

                <ProductAddtoCart 
                    configProductID={configProduct1}
                />

                <ProductAddtoCart 
                    configProductID={configProduct2}
                />

            </div>
        </div>
    )
}

export default PaintProductActions;
