import React , { useState , useEffect } from 'react';
import { useQuery , useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import mintPay from '@assets/images/mintpay-logo.webp';

import { GET_PRODUCT_COLLECTION , ADD_SIMPLE_PRODUCT_TO_CART , ADD_VIRTUAL_PRODUCT_TO_CART } from '@hooks/query';

import { selectCartToken } from '@redux/common/common.selectors';
import { setCart , setCartPrice } from '@redux/common/common.actions';

function padLeadingZeros(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

const ProductAddtoCart = ({ configProductID , cartToken , setCart , setCartPrice }) => {
    
    const [selectedPrice, setSelectedPrice] = useState(0);
    const [hasSalePrice, setHasSalePrice] = useState(false);
    const [selectedOldPrice, setSelectedOldPrice] = useState(0);
    const [selectedUpdatedPrice, setUpdatedSelectedPrice] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState(0);
    const [qty, setQty] = useState('01');
    const [selectedProductType, setSelectedProductType] = useState('simple');    
    const [addtoCartLoading, setAddtoCartLoading] = useState(false);    
    const [addtoCartText, setAddtoCartText] = useState('Add to Cart');

    /* eslint-disable */
    const { loading, error, data } = useQuery(GET_PRODUCT_COLLECTION , {
        variables: { 'productID' : configProductID },
    });
    const [addSimpleProductToCart, {}] = useMutation(ADD_SIMPLE_PRODUCT_TO_CART, { errorPolicy: 'all' });
    const [addVirtualProductToCart, {}] = useMutation(ADD_VIRTUAL_PRODUCT_TO_CART, { errorPolicy: 'all' });

    useEffect(() => { 
        
        if( data ) {

            let productSizes = JSON.parse(data.ProductCollection.size);

            if( productSizes.length > 0 ) {
                productSizes
                .map( (size , idx) => {
    
                    if( idx === 0 ) {

                        var productPrice = (size.productSalePrice) ? Number(size.productSalePrice) : Number(size.productPrice);
                        var specialPrice = size.productSalePrice ? true : false ;

                        setSelectedOldPrice(Number(size.productPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
                        setHasSalePrice(specialPrice);
                        setSelectedPrice(productPrice);
                        setUpdatedSelectedPrice(productPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));

                        if( configProductID === '1' ) {
                            setSelectedProduct(size.productsku);
                            setSelectedProductType(size.productType);
                        }
                    }
    
                    return false;
                })
            }
        }

    } , [loading , configProductID , data]);

    const onDecreaseQty = () => {

        var qtyVal = parseInt(qty, 10);
        var newVal = 1;

        if (qtyVal > 1) {
            newVal = parseFloat(qtyVal) - 1;
        } else {
            newVal = 1;
        }

        var newPrice = selectedPrice * newVal;

        setUpdatedSelectedPrice(newPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
        setQty(padLeadingZeros(newVal , 2));

    }

    const onIncreseQty = () => {

        var newVal = 1;
        var qtyVal = parseInt(qty, 10);

        newVal = parseFloat(qtyVal) + 1;

        var newPrice = selectedPrice * newVal;

        setUpdatedSelectedPrice(newPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
        setQty(padLeadingZeros(newVal , 2));

        if( !selectedProduct ) {
            let productSizes = JSON.parse(data.ProductCollection.size);
            if( productSizes.length > 0 ) {
                productSizes
                .map( (size , idx) => {
    
                    if( idx === 0 ) {
                        setSelectedProduct(size.productsku);
                        setSelectedProductType(size.productType);
                    }
    
                    return false;
                })
            }
        }

    }

    const onSelectProduct = (product) => {

        var productPrice = (product.productSalePrice) ? Number(product.productSalePrice) : Number(product.productPrice);
        var specialPrice = product.productSalePrice ? true : false ;

        setSelectedOldPrice(Number(product.productPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
        setHasSalePrice(specialPrice);
        setSelectedPrice(productPrice);
        setUpdatedSelectedPrice(productPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
        setSelectedProduct(product.productsku);
        setQty('01');
        setSelectedProductType(product.productType);

    }

    const handleAddtoCart = () => {

        let qtyVal = parseInt(qty, 10);
        setAddtoCartLoading(true);
        setAddtoCartText('Adding');

        if( selectedProductType === 'simple' ) {

            addSimpleProductToCart({
                variables: {
                    cartID: String(cartToken),
                    quantity: qtyVal,
                    sku: String(selectedProduct)
                },
                onCompleted(addSimpleCartData){
                    
                    if( addSimpleCartData ) {
                        setCart(addSimpleCartData.addSimpleProductsToCart.cart.items);
                        setCartPrice(Number(addSimpleCartData.addSimpleProductsToCart.cart.prices.grand_total.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
                        setAddtoCartText('Added');
                    }

                    setTimeout(() => {
                        setAddtoCartText('Add to Cart');
                        setAddtoCartLoading(false);
                    }, 500);

                }
            })

        } else if( selectedProductType === 'virtual' ) {

            addVirtualProductToCart({
                variables: {
                    cartID: String(cartToken),
                    quantity: qtyVal,
                    sku: String(selectedProduct)
                },
                onCompleted(addVirtualCartData){
                    
                    if( addVirtualCartData ) {
                        setCart(addVirtualCartData.addVirtualProductsToCart.cart.items);
                        setCartPrice(Number(addVirtualCartData.addVirtualProductsToCart.cart.prices.grand_total.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
                        setAddtoCartText('Added');
                    }

                    setTimeout(() => {
                        setAddtoCartText('Add to Cart');
                        setAddtoCartLoading(false);
                    }, 500);
                    
                }
            })

        }

    }

    if (loading) return 'Loading...';

    if( data ) {

        let productName = data.ProductCollection.productName;
        let productSizes = JSON.parse(data.ProductCollection.size);

        return(
            <div className="productAction" id={`productAction${configProductID}`}>
                <div className="titleWrap">
                    <h2>{productName}</h2>
                    <div className='sizeAtributesWrap'>

                        {
                            (productSizes.length > 0) ?
                            productSizes
                            .map( (size , idx) => {

                                return (
                                    <div 
                                        key={size.productID} 
                                        className={`custom-control custom-radio ${ ( selectedProduct === size.productsku ? 'selected' : '' ) }`}
                                        onClick={() => onSelectProduct( size ) }
                                    >
                                        <span className="custom-control-label">{size.sizeAttributeLabel}</span>
                                    </div>
                                );
                            })
                            : ''
                        }

                        <div className="quantity">
                            <button 
                                className="desc"
                                onClick={ () => onDecreaseQty() } 
                            ><span> - </span></button>
                            <span className='quantityNum'>{qty}</span>
                            <button 
                                className="incre"
                                onClick={() => onIncreseQty()}
                            ><span> + </span></button>
                        </div>

                    </div>
                </div>
                
                {
                    hasSalePrice ?
                    <div className='price hasSalePrice'>
                        <span className='oldPrice'>LKR {selectedOldPrice}</span>
                        LKR {selectedUpdatedPrice}
                        <div className="mintpayNote">
                            <span>or 3 installments with</span> 
                            <img src={mintPay} loading="lazy" width="100" height="33" />
                        </div>
                    </div>
                    :
                    <div className='price'>
                        LKR {selectedUpdatedPrice}
                        <div className="mintpayNote">
                            <span>or 3 installments with</span> 
                            <img src={mintPay} loading="lazy" width="100" height="33" />
                        </div>
                    </div>
                }
                
                
                <div className='addTocartWrapper'>
                    <button 
                        className='addtoCart'
                        disabled={(selectedProduct === 0 ) ? 'disabled' : addtoCartLoading ? 'disabled' : ''}
                        onClick={() => handleAddtoCart()}
                        id={productName.toLowerCase().replace(/ /g, "-")}
                    >
                        {addtoCartText}
                    </button>
                </div>

            </div>
        )
    } else {
        return null;
    }
}

const mapStateToProps = createStructuredSelector({
    cartToken : selectCartToken
});

const mapDispatchToProps = dispatch => ({
    setCart : (cart) => dispatch(setCart(cart)),
    setCartPrice : (cartPrice) => dispatch(setCartPrice(cartPrice)),
});

export default connect(mapStateToProps , mapDispatchToProps)(ProductAddtoCart);
