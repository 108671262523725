import React from 'react';
import Slider from "react-slick";

import feature1 from '@assets/images/feature1.webp';
import feature2 from '@assets/images/feature2.webp';
import feature3 from '@assets/images/feature3.webp';
import feature4 from '@assets/images/feature4.webp';
import feature5 from '@assets/images/feature5.webp';
import feature6 from '@assets/images/feature6.webp';

import slider1 from '@assets/images/productTab/int-2-thumb.webp';
import slider2 from '@assets/images/productTab/int-3-thumb.webp';
import slider3 from '@assets/images/productTab/int-4-thumb.webp';
import slider4 from '@assets/images/productTab/int-5-thumb.webp';
import slider5 from '@assets/images/productTab/int-1-thumb.webp';

const InteriorEmulsionTab = ({ settings }) => {

    return(
        <div className='tab'>

            <div className='content'>
                <h2>Interior Emulsion (Brilliant White)</h2>
                <p><strong>WHITE by JAT Interior Emulsion is a premium emulsion for interior walls and ceilings. The low sheen formulation offers outstanding opacity and whiteness.</strong></p>
            </div>

            <div className='slider'>
                <Slider {...settings}>
                    <div className='item'>
                        <img src={slider1} alt="slider1" width={450} height={265} />
                    </div>
                    <div className='item'>
                        <img src={slider2} alt="slider1" width={450} height={265} />
                    </div>
                    <div className='item'>
                        <img src={slider3} alt="slider1" width={450} height={265} />
                    </div>
                    <div className='item'>
                        <img src={slider4} alt="slider1" width={450} height={265} />
                    </div>
                    <div className='item'>
                        <img src={slider5} alt="slider1" width={450} height={265} />
                    </div>
                </Slider>
            </div>

            <div className="productFeaturesWrap">
                <div className="productFeature">
                    <img src={feature1} alt="" loading="lazy" width="138" height="138" />
                    <h5>Up to 50% of water by volume of paint</h5>
                </div>
                <div className="productFeature">
                    <img src={feature2} alt="" loading="lazy" width="138" height="138" />
                    <h5>Apply with a brush or a roller</h5>
                </div>
                <div className="productFeature">
                    <img src={feature3} alt="" loading="lazy" width="138" height="138" />
                    <h5>Stir thoroughly before use</h5>
                </div>
                <div className="productFeature">
                    <img src={feature4} alt="" loading="lazy" width="138" height="138" />
                    <h5>Wash equipment with clean&nbsp;water</h5>
                </div>
                <div className="productFeature">
                    <img src={feature5} alt="" loading="lazy" width="138" height="138" />
                    <h5>Coverage on a smooth surface</h5>
                </div>
                <div className="productFeature">
                    <img src={feature6} alt="" loading="lazy" width="138" height="138" />
                    <h5>Recoat in 2-4 hours</h5>
                </div>
            </div>

        </div>
    )
}

export default InteriorEmulsionTab;