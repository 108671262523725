import { userActionTypes } from './user.types';

const INITIAL_STATE = {
    isUserLoggedIn: false,
    user: null,
    shippingAddress : null,
    billingAddress : null,
    emailToCart : '',
    orderID : null,
    userRegistration : false,
    invoiceData: null,
    userConfirmed : false,
}

const userReducer = ( state = INITIAL_STATE , action ) => {
    switch (action.type) {
        case userActionTypes.CHECK_IS_USER_LOGGED_IN:
            return{
                ...state , 
                isUserLoggedIn : action.payload
            }
        case userActionTypes.SET_USER:
            return{
                ...state , 
                user : action.payload
            }
        case userActionTypes.SET_SHIPPING_ADDRESS:
            return{
                ...state , 
                shippingAddress : action.payload
            }
        case userActionTypes.SET_EMAIL_TO_CART:
            return{
                ...state , 
                emailToCart : action.payload
            }
        case userActionTypes.SET_BILLING_ADDRESS:
            return{
                ...state , 
                billingAddress : action.payload
            }
        case userActionTypes.SET_ORDER_ID:
            return{
                ...state , 
                orderID : action.payload
            }
        case userActionTypes.SET_USER_REGISTRATION:
            return{
                ...state , 
                userRegistration : action.payload
            }
        case userActionTypes.SET_INVOICE_DATA:
            return{
                ...state , 
                invoiceData : action.payload
            }
        case userActionTypes.SET_USER_CONFIRMED:
            return{
                ...state , 
                userConfirmed : action.payload
            }
        default:
            return state;
    }
}

export default userReducer;