import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";
import ls from 'localstorage-slim';

import App from './App';
import { store , persistor } from './redux/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import withClearCache from "./ClearCache";
//import reportWebVitals from './reportWebVitals';

ls.config.ttl = 3600; 

const token = ls.get('token') || null;

const client = new ApolloClient({
  uri: 'https://staging.whitebyjat.com/api/graphql',
  cache: new InMemoryCache(),
  cors: false,
  credentials: false,
  headers: {
    authorization: token ? `Bearer ${token}` : "",
  }
});

//const ClearCacheComponent = withClearCache(App);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter basename="/">
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>            
      </BrowserRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

//reportWebVitals();