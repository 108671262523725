import React , { lazy } from 'react';
import { Route , Routes } from 'react-router-dom';

import HomePage from '@pages/home/home.page';
//import CustomerLoginPage from '@pages/customerLogin/customerLogin.page';
//import CreateNewCustomer from '@pages/createCustomer/createCustomer.page';
//import ContactPage from '@pages/contact/contact.page';
//import TermsConditions from '@pages/termsConditions/termsConditions.page';
//import PrivacyPolicy from '@pages/privacyPolicy/privacyPolicy.page';
//import ReturnPolicy from '@pages/returnPolicy/returnPolicy.page';
//import DeliveryInformation from '@pages/deliveryInformation/deliveryInformation.page';
//import CartPage from '@pages/cart/cart.page';
//import CheckoutPage from '@pages/checkout/checkout.page';
//import PaymentPage from '@pages/payments/payments.page';
//import CustomerAccount from '@pages/customer/account.page';
//import ThankyouPage from '@pages/thankyou/thankyou.page';
//import CustomerConfirm from '@pages/customer/confirm.page';
//import NotFound from '@pages/404/notfound';

import ScrollToTop from '@functions/ScrollToTop';

const CustomerLoginPage = lazy(() => import('@pages/customerLogin/customerLogin.page'));
const CreateNewCustomer = lazy(() => import('@pages/createCustomer/createCustomer.page'));
const ContactPage = lazy(() => import('@pages/contact/contact.page'));
const TermsConditions = lazy(() => import('@pages/termsConditions/termsConditions.page'));
const PrivacyPolicy = lazy(() => import('@pages/privacyPolicy/privacyPolicy.page'));
const ReturnPolicy = lazy(() => import('@pages/returnPolicy/returnPolicy.page'));
const DeliveryInformation = lazy(() => import('@pages/deliveryInformation/deliveryInformation.page'));
const CartPage = lazy(() => import('@pages/cart/cart.page'));
const CheckoutPage = lazy(() => import('@pages/checkout/checkout.page'));
const PaymentPage = lazy(() => import('@pages/payments/payments.page'));
const CustomerAccount = lazy(() => import('@pages/customer/account.page'));
const ThankyouPage = lazy(() => import('@pages/thankyou/thankyou.page'));
const CustomerConfirm = lazy(() => import('@pages/customer/confirm.page'));
const NotFound = lazy(() => import('@pages/404/notfound'));
const ForgotPassword = lazy(() => import('@pages/customer/forgotpassword.page'));
const ResetPassword = lazy(() => import('@pages/customer/resetpassword.page'));

const routes = (
    <React.Fragment>
        <ScrollToTop>
            <Routes>
                <Route index element={<HomePage />} />
                <Route path="/sign-in" element={<CustomerLoginPage />} />
                <Route path="/create-account" element={<CreateNewCustomer />} />
                <Route path="/contact-us" element={<ContactPage />} />
                <Route path="/terms-and-conditions" element={<TermsConditions />} />
                <Route path="/privacy-policy-cookie-restriction-mode" element={<PrivacyPolicy />} />
                <Route path="/return-policy" element={<ReturnPolicy />} />
                <Route path="/delivery-information" element={<DeliveryInformation />} />
                <Route path="/cart" element={<CartPage />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path="/checkout/payment" element={<PaymentPage />} />
                <Route path="/checkout/success" element={<ThankyouPage />} />
                <Route path="/checkout/failed" element={<ThankyouPage />} />
                <Route path="/customer/account" element={<CustomerAccount />} >
                    <Route path="orders" element={<CustomerAccount />} >
                        <Route path=":orderid" element={<CustomerAccount />} />
                    </Route>
                    <Route path="address" element={<CustomerAccount />} >
                        <Route path=":addressid" element={<CustomerAccount />} />
                        <Route path="new" element={<CustomerAccount />} />
                    </Route>
                    <Route path="edit" element={<CustomerAccount />} />
                </Route>
                <Route path="/customer/confirm" element={<CustomerConfirm />} />
                <Route path="/customer/forgotpassword" element={<ForgotPassword />} />
                <Route path="/customer/resetpassword" element={<ResetPassword />} />
                <Route path='*' element={<NotFound />}/>
            </Routes>
        </ScrollToTop>
    </React.Fragment>
);

export default routes;