import { commonActionTypes } from './common.types';

export const setCartCount = cartCount => ({
    type : commonActionTypes.SET_CART_COUNT,
    payload : cartCount
});

export const setCartPrice = cartPrice => ({
    type : commonActionTypes.SET_CART_PRICE,
    payload : cartPrice
});

export const setCart = cart => ({
    type : commonActionTypes.SET_CART,
    payload : cart
});

export const setCartToken = cartToken => ({
    type : commonActionTypes.SET_CART_TOKEN,
    payload : cartToken
});

export const setAvailableShippingMethods = availableShippingMethods => ({
    type : commonActionTypes.SET_AVAILABLE_SHIPPING_METHOD,
    payload : availableShippingMethods
});

export const setCartLoading = cartLoading => ({
    type : commonActionTypes.CART_LOADING,
    payload : cartLoading
});