import React from 'react';
import { Link } from 'react-router-dom';
import {isMobile} from 'react-device-detect';

import footerLogo from '@assets/images/footer-logo.webp';
import facebook from '@assets/images/facebook.webp';
import mayalogo from '@assets/images/mayalogo-black.webp';

const Footer = () => {

    var paddingStyle = isMobile ? '3rem 1rem 2rem' : '3rem 3rem 2rem' ;

    return(
        <footer style={{
            backgroundColor : '#111e14',
            padding: paddingStyle
        }}>
            <div className="container">
                <div className="footerBox logoWrap">
                    <div className="logo">
                        <img src={footerLogo} alt="JAT Emulsion" loading="lazy" width="120" height="70" />
                    </div>
                </div>
                <div className="contactDetails">
                    <address>JAT Holdings PLC, <br />No: 351, Pannipitiya road, <br />Thalawathugoda, <br />Sri Lanka.</address>
                    <div className="other">
                        <p><span className="label">Phone: </span><a href="tel:011%207 524 300">011 7 524 300</a></p>
                        <p><span className="label">Fax: </span>011 2 773 793</p>
                        <p><span className="label">Email: </span><a href="mailto:whitebyjat@jatholdings.com">whitebyjat@jatholdings.com</a></p>
                    </div>
                </div>
                <div className="navWrap">
                    <ul className="nav">
                        <li className="nav-item">
                            <Link to="/terms-and-conditions">Terms and Conditions</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/privacy-policy-cookie-restriction-mode">Privacy Policy</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/return-policy">Return Policy</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/delivery-information">Delivery Information</Link>
                        </li>
                    </ul>
                </div>
                <div className="footerBox creator">
                    <a href="https://www.facebook.com/jattransformingspaces/" target="_blank" rel="noopener noreferrer">
                        <img src={facebook} alt="Facebook" loading="lazy" width="137" height="45" />
                    </a>
                    <a href="https://maya.lk" target="_blank" rel="noopener noreferrer">
                        <span>Crafted by</span>
                        <img src={mayalogo} alt="Maya.lk" loading="lazy" width="137" height="43" />
                    </a>
                </div>
            </div>
            <div className="container bottomWrap">
                <div className="copyrights">All rights reserved {new Date().getFullYear()}</div>
                {/* <div className="footerMenu">
                    <ul className="nav">
                        <li className="nav-item">
                            <Link to="/terms-and-conditions">Terms and Conditions</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/privacy-policy-cookie-restriction-mode">Privacy Policy</Link>
                        </li>
                    </ul>
                </div> */}
            </div>
        </footer>
    )
}

export default Footer;
