import React , { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useMutation , useLazyQuery } from '@apollo/client';
import ls from 'localstorage-slim';

import { selectIsUserLoggedIn } from '@redux/user/user.selectors';

import { setCartToken , setCart , setCartPrice , setCartLoading } from '@redux/common/common.actions';
import { setIsUserLoggedIn , setUser } from '@redux/user/user.actions';

import { 
    CREATE_EMPTY_CART , 
    CUSTOMER_CART , 
    GET_CUSTOMER , 
    GET_CUSTOMER_CART , 
    CREATE_EMPTY_CART_WITH_CART_ID , 
    GET_CART_DETAILS 
} from '../hooks/query';

const Authentication = ({ isUserLoggedIn , setIsUserLoggedIn , setUser , setCartToken , setCart , setCartPrice , setCartLoading }) => {

    /* eslint-disable */
    const [createEmptyCartToken] = useMutation(CREATE_EMPTY_CART);
    const [customerCart] = useLazyQuery(CUSTOMER_CART);
    const [getCustomerData] = useLazyQuery(GET_CUSTOMER);
    const [getCustomerCart] = useLazyQuery(GET_CUSTOMER_CART);
    const [createEmptyCartTokenWithCartID] = useMutation(CREATE_EMPTY_CART_WITH_CART_ID);
    const [getCartDetails] = useLazyQuery(GET_CART_DETAILS);

    const checkUserIsLoggedIn = () => {

        const token = ls.get('token') || null;

        if( token ) {
            setCartLoading(true);
            customerCart({
                onCompleted(customerCartToken){
                    if( customerCartToken ) {
                        ls.set('cartToken', customerCartToken.customerCart.id);
                        setCartToken(customerCartToken.customerCart.id);
                        setIsUserLoggedIn(true);
                    }
                },
                onError({ graphQLErrors, networkError }) {
                    /* if (graphQLErrors) {
                        graphQLErrors.forEach(({ message }) =>  setFormError(message) );
                    } */
                }
            });

            getCustomerData({
                onCompleted(customerData){
                    if( customerData ) {
                        setUser(customerData.customer);
                    }
                },
                onError({ graphQLErrors, networkError }) {
                    /* if (graphQLErrors) {
                        graphQLErrors.forEach(({ message }) =>  setFormError(message) );
                    } */
                }
            });

            getCustomerCart({
                onCompleted(customerCartData){
                    if( customerCartData ) {
                        setCart(customerCartData.customerCart.items);
                        setCartPrice(Number(customerCartData.customerCart.prices.grand_total.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
                        setCartLoading(false);
                    }
                },
                onError({ graphQLErrors, networkError }) {
                    /* if (graphQLErrors) {
                        graphQLErrors.forEach(({ message }) =>  setFormError(message) );
                    } */
                }
            })
        }
    }

    useEffect(() => {

        const storageCartToken = ls.get('cartToken') || null;

        if( !isUserLoggedIn ){
            setCartLoading(true);

            if( !storageCartToken ) {
                createEmptyCartToken({
                    onCompleted(emptyCartData){
                        if( emptyCartData ) {
                            ls.set('cartToken', emptyCartData.createEmptyCart , 3600);
                            setCartToken(emptyCartData.createEmptyCart);
                            setCartLoading(false);
                        }
                    }
                });
            } else if( storageCartToken ) {
                
                createEmptyCartTokenWithCartID({
                    variables: {
                        cartID: String(storageCartToken),
                    },
                    onCompleted(emptyCartData){
                        if( emptyCartData ) {
                            ls.set('cartToken', emptyCartData.createEmptyCart);
                            setCartToken(emptyCartData.createEmptyCart);
                            setCartLoading(false);
                        }
                    },
                    onError({ graphQLErrors, networkError }) {
                        if (graphQLErrors) {
                            //graphQLErrors.forEach(({ message }) =>  setFormError(message) );
                            /* createEmptyCartToken({
                                onCompleted(emptyCartData){
                                    if( emptyCartData ) {
                                        ls.set('cartToken', emptyCartData.createEmptyCart);
                                        setCartToken(emptyCartData.createEmptyCart);
                                    }
                                }
                            }); */
                        }
                    }
                });
    
                getCartDetails({
                    variables: {
                        cartID: storageCartToken,
                    },
                    onCompleted(cartDetails){
                        if( cartDetails.cart ) {
                            setCart(cartDetails.cart.items);
                            setCartPrice(Number(cartDetails.cart.prices.grand_total.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
                            setCartLoading(false);
                        }
                    },
                    onError({ graphQLErrors, networkError }) {
                        /* if (graphQLErrors) {
                            graphQLErrors.forEach(({ message }) =>  setFormError(message) );
                        } */
                    }
                });
            }

            
        } else {

        }

        window.addEventListener('load', checkUserIsLoggedIn);

        return () => {
            window.removeEventListener('load', checkUserIsLoggedIn);
        };

    } , [isUserLoggedIn , setCartToken , createEmptyCartToken , customerCart]);

    return (
        <></>
    )
}

const mapDispatchToProps = dispatch => ({
    setIsUserLoggedIn : (isUserLoggedIn) => dispatch(setIsUserLoggedIn(isUserLoggedIn)),    
    setUser : (user) => dispatch(setUser(user)),
    setCartToken : (cartToken) => dispatch(setCartToken(cartToken)),
    setCart : (cart) => dispatch(setCart(cart)),
    setCartPrice : (cartPrice) => dispatch(setCartPrice(cartPrice)),
    setCartLoading : (cartLoading) => dispatch(setCartLoading(cartLoading)),
});

const mapStateToProps = createStructuredSelector({
    isUserLoggedIn : selectIsUserLoggedIn
});

export default connect(mapStateToProps , mapDispatchToProps)(Authentication);