import React , { useState, useEffect } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BrowserView, MobileView } from 'react-device-detect';
import ls from 'localstorage-slim';

import Logo from '@assets/images/logo.webp';
import Menu from './menu.component';
//import TickerComponent from '@components/ticker/ticker.component';

import { selectCart , selectCartPrice } from '@redux/common/common.selectors';
import { selectIsUserLoggedIn , selectUser } from '@redux/user/user.selectors';

import { setIsUserLoggedIn , setUser  } from '@redux/user/user.actions';
import { setCart , setCartPrice } from '@redux/common/common.actions';

//const LogoutSwal = withReactContent(Swal);

const Header = ({ cart , isUserLoggedIn , user , setIsUserLoggedIn , setUser , cartPrice , setCart , setCartPrice }) => {

    let navigate = useNavigate();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [toggleMenu, setToggleMenu] = useState(false);
    const [cartCount, setCartCount] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        let cartCounter = 0;

        if( cart.length > 0 ) {
            cart
            .map( cartItem => cartCounter += cartItem.quantity )
        }

        setCartCount(cartCounter);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [setCartCount , cart]);

    const scrollClass = ( scrollPosition >= 82 ) ? 'sticky' : '';

    const onLogout = () => {
        ls.remove('token');
        ls.remove('cartToken');
        setIsUserLoggedIn(false);
        setUser(null);
        setCart([]);
        setCartPrice('0');

        navigate('/');
        window.location.reload();
    }
    
    return(
        <header className={`mainHeaderWrap ${scrollClass}`}> 
            {/* <TickerComponent /> */}
            <BrowserView className='container'>
                <div className='logo'>
                    <Link to="/">
                        <img src={Logo} alt="JAT Emulsion" loading="lazy" width="115" height="115" />
                    </Link>
                </div>
                <Menu 
                    isUserLoggedIn={isUserLoggedIn}
                    user={user}
                    onLogout={onLogout}
                    cart={cart}
                    cartPrice={cartPrice}
                    setToggleMenu={setToggleMenu}
                    cartCount={cartCount}
                />
            </BrowserView>

            <MobileView className='container'>
                <div className='logo'>
                    <Link to="/">
                        <img src={Logo} alt="JAT Emulsion" loading="lazy" width="115" height="115" />
                    </Link>
                </div>
                <div className="header-contact">
                    <div className="tele"><span className="teleWrap">+94117 524 300 / +9477 897 4849 </span></div>
                </div>
                <div className='shoppingCart'>
                    <Link to="/cart" onClick={() => setToggleMenu(false)}>
                        <span className='countWrap'>
                            <span className='count'>{cartCount}</span>
                            <i className='fas fa-shopping-cart'></i>
                        </span>
                    </Link>
                </div>
                <span 
                    className='toggleMenu'
                    onClick={() => setToggleMenu(!toggleMenu) }
                >
                    {
                        toggleMenu ? <i className='fa fa-times'></i> : <i className='fa fa-bars'></i>
                    }
                </span>
                {
                    (toggleMenu) ?
                    <Menu 
                        isUserLoggedIn={isUserLoggedIn}
                        user={user}
                        onLogout={onLogout}
                        cart={cart}
                        cartPrice={cartPrice}
                        setToggleMenu={setToggleMenu}
                        cartCount={cartCount}
                    />
                    : ''
                }
            </MobileView>
        </header>
    )
}

const mapDispatchToProps = dispatch => ({
    setIsUserLoggedIn : (isUserLoggedIn) => dispatch(setIsUserLoggedIn(isUserLoggedIn)),    
    setUser : (user) => dispatch(setUser(user)),
    setCart: (cart) => dispatch(setCart(cart)), 
    setCartPrice: (cartPrice) => dispatch(setCartPrice(cartPrice)), 
});

const mapStateToProps = createStructuredSelector({
    cart : selectCart,
    isUserLoggedIn : selectIsUserLoggedIn , 
    user : selectUser,
    cartPrice : selectCartPrice,
});

export default connect(mapStateToProps , mapDispatchToProps)(Header);
