import React , { Suspense } from 'react';

import routes from './routes';
import Header from '@components/header/header.componet';
import Footer from '@components/footer/footer.component';
//import Chat from '@components/chat/chat.component';

import Authentication from './functions/authentication';

import 'react-loading-skeleton/dist/skeleton.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.scss';

function App() {
  return (
    <div className="appSectionWrap">
      <Authentication />
      <Header />
      <Suspense fallback={<></>}>
        {routes}
      </Suspense>
      <Footer />
      {/* <Chat /> */}
    </div>
  );
}

export default App;