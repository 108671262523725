import React from 'react';
import Slider from "react-slick";

import feature1 from '@assets/images/ext_pesant.webp';
import feature2 from '@assets/images/feature2.webp';
import feature3 from '@assets/images/feature3.webp';
import feature4 from '@assets/images/feature4.webp';
import feature5 from '@assets/images/ext_130.webp';
import feature6 from '@assets/images/feature6.webp';

import slider1 from '@assets/images/productTab/ext-1.webp';
import slider2 from '@assets/images/productTab/ext-2-thumb.webp';
import slider3 from '@assets/images/productTab/ext-3-thumb.webp';
import slider4 from '@assets/images/productTab/ext-4-thumb.webp';
import slider5 from '@assets/images/productTab/ext-5-thumb.webp';

const ExteriorEmulsionTab = ({ settings }) => {

    return(
        <div className='tab'>
            <div className='content'>
                <h2>Exterior Weather Coat (Brilliant White)</h2>
                <p><strong>WHITE by JAT Exterior Weather Coat is ideal for all forms of masonry and provides superior protection against all weather conditions. The attractive, smooth matte finish stays cleaner, longer and offers outstanding obliterating power.</strong></p>
            </div>

            <div className='slider'>
                <Slider {...settings}>
                    <div className='item'>
                        <img src={slider1} alt="slider1" width={1200} height={900} />
                    </div>
                    <div className='item'>
                        <img src={slider2} alt="slider1" width={450} height={265} />
                    </div>
                    <div className='item'>
                        <img src={slider3} alt="slider1" width={450} height={265} />
                    </div>
                    <div className='item'>
                        <img src={slider4} alt="slider1" width={450} height={265} />
                    </div>
                    <div className='item'>
                        <img src={slider5} alt="slider1" width={450} height={265} />
                    </div>
                </Slider>
            </div>

            <div className="productFeaturesWrap">
                <div className="productFeature">
                    <img src={feature1} alt="" loading="lazy" width="138" height="138" />
                    <h5>Up to 20% of water by volume of paint</h5>
                </div>
                <div className="productFeature">
                    <img src={feature2} alt="" loading="lazy" width="138" height="138" />
                    <h5>Apply with a brush or a roller</h5>
                </div>
                <div className="productFeature">
                    <img src={feature3} alt="" loading="lazy" width="138" height="138" />
                    <h5>Stir thoroughly before use</h5>
                </div>
                <div className="productFeature">
                    <img src={feature4} alt="" loading="lazy" width="138" height="138" />
                    <h5>Wash equipment with clean&nbsp;water</h5>
                </div>
                <div className="productFeature">
                    <img src={feature5} alt="" loading="lazy" width="138" height="138" />
                    <h5>Coverage on a smooth surface</h5>
                </div>
                <div className="productFeature">
                    <img src={feature6} alt="" loading="lazy" width="138" height="138" />
                    <h5>Recoat in 2-3 hours</h5>
                </div>
            </div>

        </div>
    )
}

export default ExteriorEmulsionTab;
