import React from 'react';
import Slider from "react-slick";

import feature1 from '@assets/images/feature1.webp';
import feature2 from '@assets/images/feature2.webp';
import feature3 from '@assets/images/feature3.webp';
import feature4 from '@assets/images/feature4.webp';
import feature5 from '@assets/images/filler.webp';
import feature6 from '@assets/images/30-min.webp';

import slider1 from '@assets/images/productTab/Interior-Wall-Filler.webp';

const AcrylicInteriorTab = ({ settings }) => {

    return(
        <div className='tab'>
            <div className='content'>
                <h2>WHITE by JAT Acrylic Interior Wall Filler</h2>
                <p><strong>WHITE by JAT Acrylic Interior Wall Filler is a high-quality primer that provides body to the paint film and consists of a thick paint augmented with fillers and plasticizers designed to repair any minor imperfection, cracks or holes that you want to get rid of, allowing you to have perfectly blemish-free walls and ceilings ready to paint. </strong></p>
            </div>

            <div className='slider'>
                <Slider {...settings}>
                    <div className='item'>
                        <img src={slider1} alt="slider1" width={1200} height={744} />
                    </div>
                </Slider>
            </div>

            <div className="productFeaturesWrap">
                <div className="productFeature">
                    <img src={feature1} alt="" loading="lazy" width="138" height="138" />
                    <h5>Up to 50% of water by volume of paint</h5>
                </div>
                <div className="productFeature">
                    <img src={feature2} alt="" loading="lazy" width="138" height="138" />
                    <h5>Apply with a brush or a roller</h5>
                </div>
                <div className="productFeature">
                    <img src={feature3} alt="" loading="lazy" width="138" height="138" />
                    <h5>Stir thoroughly before use</h5>
                </div>
                <div className="productFeature">
                    <img src={feature4} alt="" loading="lazy" width="138" height="138" />
                    <h5>Wash equipment with clean&nbsp;water</h5>
                </div>
                <div className="productFeature">
                    <img src={feature5} alt="" loading="lazy" width="138" height="138" />
                    <h5>Coverage on a smooth surface</h5>
                </div>
                <div className="productFeature">
                    <img src={feature6} alt="" loading="lazy" width="138" height="138" />
                    <h5>Recoat in 2-3 hours</h5>
                </div>
            </div>

        </div>
    )
}

export default AcrylicInteriorTab;
