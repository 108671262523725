import React , { useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import Caculator from '@components/calculator/calculator.componet';

const PaintCalculator = () => {

    const [showEmulsionCal, setShowEmulsionCal] = useState(false);
    const [showSealerCal, setShowSealerCal] = useState(false);

    const handleEmulsionCal = () => {
        setShowEmulsionCal(!showEmulsionCal);
    }

    const handleSealerCal = () => {
        setShowSealerCal(!showSealerCal);
    }

    return (
        <div className='paintCalculatorWrap'>
            <div className='container'>
                <h2>Paint Calculator</h2>

                <div className="calculators">

                    <BrowserView>
                        <div className="proGroup"><span>INTERIOR AND EXTERIOR EMULSION</span></div>
                        <div className='calculatorWrap'>
                            <Caculator 
                                additinalClass="interiorCal"
                                title="Interior Paint Calculator"
                                liters="225"
                            />

                            <Caculator 
                                additinalClass="exteriorCal"
                                title="Exterior Paint Calculator"
                                liters="130"
                            />
                        </div>
                    </BrowserView>

                    <MobileView>
                        <div 
                            className="proGroup"
                            onClick={() => handleEmulsionCal()}
                        >
                            <span>INTERIOR AND EXTERIOR EMULSION</span>
                            {
                                showEmulsionCal ? <i className='fa fa-minus'></i> : <i className='fa fa-plus'></i>
                            }
                        </div>
                        {
                            (showEmulsionCal) ?
                            <div className='calculatorWrap'>
                                <Caculator 
                                    additinalClass="interiorCal"
                                    title="Interior Paint Calculator"
                                    liters={process.env.REACT_APP_INTERIOR_CAL}
                                />

                                <Caculator 
                                    additinalClass="exteriorCal"
                                    title="Exterior Paint Calculator"
                                    liters={process.env.REACT_APP_EXTERIOR_CAL}
                                />
                            </div>
                            : ''
                        }
                    </MobileView>

                </div>

                <div className="calculators">

                    <BrowserView>
                        <div className="proGroup"><span>WALL FILLER AND SEALER</span></div>
                        <div className='calculatorWrap'>
                            <Caculator 
                                additinalClass="interiorCal"
                                title="INTERIOR WALL FILLER Calculator"
                                liters={process.env.REACT_APP_INTERIOR_WALL_FILLER_CAL}
                            />

                            <Caculator 
                                additinalClass="exteriorCal"
                                title="EXTERIOR SEALER Calculator"
                                liters={process.env.REACT_APP_EXTERIOR_SEALER_CAL}
                            />
                        </div>
                    </BrowserView>

                    <MobileView>
                        <div 
                            className="proGroup"
                            onClick={() => handleSealerCal()}
                        >
                            <span>WALL FILLER AND SEALER</span>
                            {
                                showSealerCal ? <i className='fa fa-minus'></i> : <i className='fa fa-plus'></i>
                            }
                        </div>
                        {
                            (showSealerCal) ?
                            <div className='calculatorWrap'>
                                <Caculator 
                                    additinalClass="interiorCal"
                                    title="INTERIOR WALL FILLER Calculator"
                                    liters="180"
                                />

                                <Caculator 
                                    additinalClass="exteriorCal"
                                    title="EXTERIOR SEALER Calculator"
                                    liters="110"
                                />
                            </div>
                            : ''
                        }
                    </MobileView>

                </div>

            </div>
        </div>
    )
}

export default PaintCalculator;
