export const userActionTypes = {
    CHECK_IS_USER_LOGGED_IN : 'CHECK_IS_USER_LOGGED_IN',
    SET_USER : 'SET_USER',
    SET_SHIPPING_ADDRESS : 'SET_SHIPPING_ADDRESS',
    SET_BILLING_ADDRESS : 'SET_BILLING_ADDRESS',
    SET_EMAIL_TO_CART : 'SET_EMAIL_TO_CART',
    SET_ORDER_ID : 'SET_ORDER_ID',
    SET_USER_REGISTRATION : 'SET_USER_REGISTRATION',
    SET_INVOICE_DATA : 'SET_INVOICE_DATA',
    SET_USER_CONFIRMED : 'SET_USER_CONFIRMED',
}