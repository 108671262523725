import React , { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import MiniCartItem from './miniCartItem.component';

import { selectCartPrice , selectCart } from '@redux/common/common.selectors';

const MiniCart = ({ cartPrice , cart }) => {

    const [showDetail, setShowDetail] = useState(false);
    const [cartCount, setCartCount] = useState(0);

    useEffect(() => {

        let cartCounter = 0;

        if( cart.length > 0 ) {
            cart
            .map( cartItem => cartCounter += cartItem.quantity )
        }

        setCartCount(cartCounter);

    }, [setCartCount , cart]);

    const onToggleCartDetails = () => {
        setShowDetail(!showDetail)
    }

    return(
        <div className={`miniCartWrapper ${showDetail ? 'showDetails' : ''}`}>

            {
                (showDetail) ?
                <div className='cartDetails'>
                    {
                        (cart.length > 0) ?
                        cart
                        .map( cartItem => <MiniCartItem key={cartItem.id} {...cartItem} /> )
                        : <span className='empty'>You have no items in your shopping cart.</span>
                    }
                </div>
                : ''
            }

            <div className='itemDetailsWrap'>
                <div className='minicartTotals'>
                    <div 
                        className='count'
                        onClick={() => onToggleCartDetails()}
                    >
                        <span className='counter-label'>{cartCount} Items</span> in the Order
                        <span className='icons'>
                            {
                                (showDetail) ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>
                            }
                        </span>
                    </div>
                    <div className='total'>
                        Order Total <span className='price'>LKR {cartPrice}</span>
                    </div>
                </div>
                <Link to="/cart" className='checkOutBtn' id="viewShoppingCart">Check out</Link>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    cartPrice : selectCartPrice,
    cart : selectCart
});

export default connect(mapStateToProps)(MiniCart);
