import React , { useState , useEffect , useRef } from 'react';
import { Link } from 'react-router-dom';
import { BrowserView } from 'react-device-detect';

import Dropdown from './dropdown';

const Menu = ({ isUserLoggedIn , user , onLogout , cart , cartPrice , setToggleMenu , cartCount }) => {

    const [dropDownToggle, setdropDownToggle] = useState(false);
    const [toggleAccountDropDown, setToggleAccountDropDown] = useState(false);
    const wrapperRef = useRef(null);
    const accountWrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef,setdropDownToggle);
    useOutsideAlerter(accountWrapperRef,setToggleAccountDropDown);

    return (
        <div className='menuWrap'>
            <ul>
                <li>
                    <Link to="/" onClick={() => setToggleMenu(false)}>Home</Link>
                </li>
                <li>
                    <Link to="/contact-us" onClick={() => setToggleMenu(false)}>Contact</Link>
                </li>
                <li ref={wrapperRef}>
                    <span className='link' onClick={() => setdropDownToggle(!dropDownToggle)}>Products <i className="fas fa-caret-down"></i></span>
                    {
                        dropDownToggle && 
                        <Dropdown 
                            setdropDownToggle={setdropDownToggle} 
                            dropDownToggle={dropDownToggle} 
                            setToggleMenu={setToggleMenu}
                        />
                    }
                    
                </li>
                <BrowserView>
                    <li className='phoneNum'>
                        <span className='numWrap'>
                            <i className='fas fa-phone-alt'></i>
                            <a href="tel:+94117524300">+94117 524 300</a> / <a href="tel:+94778974849">+9477 897 4849</a> 
                        </span>
                    </li>
                </BrowserView>
                {
                    (isUserLoggedIn) ?
                    <li className='loggedIn' ref={accountWrapperRef}>
                        <span
                            onClick={() => setToggleAccountDropDown(!toggleAccountDropDown) }
                        ><i className='fas fa-user'></i> Hi , { user ? user.firstname : ''}</span>
                        {
                            (toggleAccountDropDown) ?
                            <div className="dropdown-menu">
                                <Link 
                                    className="dropdown-item" 
                                    to="/customer/account" 
                                    onClick={() => {
                                        setToggleMenu(false);
                                        setToggleAccountDropDown(false);
                                    }}>My Account</Link>
                                <span 
                                    className="dropdown-item"
                                    onClick={() => {
                                        onLogout();
                                        setToggleMenu(false);                                        
                                        setToggleAccountDropDown(false)
                                    }}
                                >Logout</span>
                            </div>
                            : ''
                        }
                    </li>
                    :
                    <li>
                        <Link to="/sign-in" onClick={() => setToggleMenu(false)}><i className='fas fa-user'></i> Sign In</Link>
                    </li>
                    
                }
                <BrowserView>
                    <li className='shoppingCart'>
                        <Link to="/cart" onClick={() => setToggleMenu(false)}>
                            <span className='countWrap'>
                                <span className='count'>{cartCount}</span>
                                <i className='fas fa-shopping-cart'></i>
                            </span>
                            <span className='price'>
                                {
                                    (cart.length > 0 ) ? `LKR ${cartPrice}` : 'LKR 0.00'
                                }
                            </span>
                        </Link>
                    </li>
                </BrowserView>
            </ul>
        </div>
    )
}

function useOutsideAlerter(ref,setdropDownToggle) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setdropDownToggle(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref , setdropDownToggle]);
}

export default Menu;
