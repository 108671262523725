import React from 'react';
import MainSlider from '@components/mainSlider/mainSlider.component';
import PaintCalculator from '@components/paintCalculator/paintCalculator.componet';
import PlaceYourOrder from '@components/placeYourOrder/placeYourOrder.component';
import ProductTabs from '@components/productTabs/productTabs.componet';
import TechnicalScope from '@components/technicalScope/technicalScope.component';
//import Loader from '@components/loader/loader';

 const HomePage = () => {
    return(
        <div className='homepageWrap'>
            {/* <Loader /> */}
            <MainSlider />
            <PaintCalculator />
            <PlaceYourOrder />
            <ProductTabs />
            <TechnicalScope />
        </div>
    )
}

export default HomePage;