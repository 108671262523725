import React , { useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import interiorEmulsion from '@assets/images/int.webp';
import exteriorEmulsion from '@assets/images/ext-1edit.webp';
import acrylicInterior from '@assets/images/int-fillter.webp';
import acrylicExterior from '@assets/images/ext-filler.webp';

import InteriorEmulsionTab from '@components/productTabContents/interiorEmulsion.component';
import ExteriorEmulsionTab from '@components/productTabContents/exteriorEmulsion.component';
import AcrylicInteriorTab from '@components/productTabContents/acrylicInterior.component';
import AcrylicExteriorTab from '@components/productTabContents/acrylicExterior.component';

const ProductTabs = () => {

    const [activeTab, setActiveTab] = useState('interiorEmulsion');

    const onSetActiveTab = (tab) => {
        setActiveTab(tab);
    }

    var sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true
    };

    return(
        <div className='productTabWrap'>
            <BrowserView className='container'>

                <div className='tabHeaderWrap'>

                    <h3>Interior and Exterior Emulsion</h3>

                    <div 
                        className={`tabHead ${ (activeTab === 'interiorEmulsion') ? 'active' : '' }`}
                        onClick={() => onSetActiveTab('interiorEmulsion')}
                    >
                        <span className='imgWrap'>
                            <img src={interiorEmulsion} alt="int" loading="lazy" width="125" height="101" />
                        </span>
                        <span className="titleWrap">Interior Emulsion <br /> (Brilliant White)</span>
                    </div>

                    <div 
                        className={`tabHead ${ (activeTab === 'exteriorEmulsion') ? 'active' : '' }`}
                        onClick={() => onSetActiveTab('exteriorEmulsion')}
                    >
                        <span className='imgWrap'>
                            <img 
                                src={exteriorEmulsion} 
                                alt="int" 
                                style={{
                                    maxWidth : `125px`,
                                    height : '102px',
                                    objectFit : 'cover'
                                }}
                                loading="lazy" 
                                width="932" 
                                height="626" 
                            />
                        </span>
                        <span className="titleWrap">Exterior Weather Coat <br /> (Brilliant White)</span>
                    </div>

                    <h3
                        style={{
                            marginTop: '4rem'
                        }}
                    >Wall Filler and Sealer</h3>

                    <div 
                        className={`tabHead ${ (activeTab === 'acrylicInterior') ? 'active' : '' }`}
                        onClick={() => onSetActiveTab('acrylicInterior')}
                    >
                        <span className='imgWrap'>
                            <img src={acrylicInterior} alt="int" loading="lazy" width="149" height="100" />
                        </span>
                        <span className="titleWrap">Acrylic Interior <br /> Wall Filler</span>
                    </div>

                    <div 
                        className={`tabHead ${ (activeTab === 'acrylicExterior') ? 'active' : '' }`}
                        onClick={() => onSetActiveTab('acrylicExterior')}
                    >
                        <span className='imgWrap'>
                            <img src={acrylicExterior} alt="int" loading="lazy" width="151" height="100" />
                        </span>
                        <span className="titleWrap">ACRYLIC EXTERIOR <br /> WALL SEALER</span>
                    </div>

                </div>

                <div className='tabContentWrap'>
                    {
                        (activeTab === 'interiorEmulsion') ? 
                        <InteriorEmulsionTab 
                            settings={sliderSettings}
                        />
                        : ''
                    }

                    {
                        (activeTab === 'exteriorEmulsion') ? 
                        <ExteriorEmulsionTab 
                            settings={sliderSettings}
                        />
                        : ''
                    }

                    {
                        (activeTab === 'acrylicInterior') ? 
                        <AcrylicInteriorTab 
                            settings={sliderSettings}
                        />
                        : ''
                    }

                    {
                        (activeTab === 'acrylicExterior') ? 
                        <AcrylicExteriorTab 
                            settings={sliderSettings}
                        />
                        : ''
                    }
                </div>

            </BrowserView>

            <MobileView className='container'>

                <div className='tabHeaderWrap'>

                    <h3>Interior and Exterior Emulsion</h3>

                    <div 
                        className={`tabHead ${ (activeTab === 'interiorEmulsion') ? 'active' : '' }`}
                        onClick={() => onSetActiveTab('interiorEmulsion')}
                    >
                        <span className='imgWrap'>
                            <img src={interiorEmulsion} alt="int" loading="lazy" width="125" height="101" />
                        </span>
                        <span className="titleWrap">Interior Emulsion <br /> (Brilliant White)</span>
                    </div>

                    {
                        (activeTab === 'interiorEmulsion') ? 
                        <InteriorEmulsionTab 
                            settings={sliderSettings}
                        />
                        : ''
                    }

                    <div 
                        className={`tabHead ${ (activeTab === 'exteriorEmulsion') ? 'active' : '' }`}
                        onClick={() => onSetActiveTab('exteriorEmulsion')}
                    >
                        <span className='imgWrap'>
                            <img 
                                src={exteriorEmulsion} 
                                alt="int" 
                                loading="lazy" 
                                width="932" 
                                height="626" 
                            />
                        </span>
                        <span className="titleWrap">Exterior Emulsion <br /> (Brilliant White)</span>
                    </div>

                    {
                        (activeTab === 'exteriorEmulsion') ? 
                        <ExteriorEmulsionTab 
                            settings={sliderSettings}
                        />
                        : ''
                    }

                    <h3
                        style={{
                            marginTop: '4rem'
                        }}
                    >Wall Filler and Sealer</h3>

                    <div 
                        className={`tabHead ${ (activeTab === 'acrylicInterior') ? 'active' : '' }`}
                        onClick={() => onSetActiveTab('acrylicInterior')}
                    >
                        <span className='imgWrap'>
                            <img src={acrylicInterior} alt="int" loading="lazy" width="149" height="100" />
                        </span>
                        <span className="titleWrap">Acrylic Interior <br /> Wall Filler</span>
                    </div>

                    {
                        (activeTab === 'acrylicInterior') ? 
                        <AcrylicInteriorTab 
                            settings={sliderSettings}
                        />
                        : ''
                    }

                    <div 
                        className={`tabHead ${ (activeTab === 'acrylicExterior') ? 'active' : '' }`}
                        onClick={() => onSetActiveTab('acrylicExterior')}
                    >
                        <span className='imgWrap'>
                            <img src={acrylicExterior} alt="int" loading="lazy" width="151" height="100" />
                        </span>
                        <span className="titleWrap">ACRYLIC EXTERIOR <br /> WALL SEALER</span>
                    </div>

                    {
                        (activeTab === 'acrylicExterior') ? 
                        <AcrylicExteriorTab 
                            settings={sliderSettings}
                        />
                        : ''
                    }

                </div>


            </MobileView>
        </div>
    )
}

export default ProductTabs;
