import { userActionTypes } from './user.types';

export const setIsUserLoggedIn = isUserLoggedIn => ({
    type : userActionTypes.CHECK_IS_USER_LOGGED_IN,
    payload : isUserLoggedIn
});

export const setUser = user => ({
    type : userActionTypes.SET_USER,
    payload : user
});

export const setShippingAddress = shippingAddress => ({
    type : userActionTypes.SET_SHIPPING_ADDRESS,
    payload : shippingAddress
});

export const setEmailToCart = emailToCart => ({
    type : userActionTypes.SET_EMAIL_TO_CART,
    payload : emailToCart
});

export const setBillingAddress = billingAddress => ({
    type : userActionTypes.SET_BILLING_ADDRESS,
    payload : billingAddress
});

export const setOrderID = orderID => ({
    type : userActionTypes.SET_ORDER_ID,
    payload : orderID
});

export const setUserRegistration = userRegistration => ({
    type : userActionTypes.SET_USER_REGISTRATION,
    payload : userRegistration
});

export const setInvoiceData = invoiceData => ({
    type : userActionTypes.SET_INVOICE_DATA,
    payload : invoiceData
});

export const setUserConfirmed = userConfirmed => ({
    type : userActionTypes.SET_USER_CONFIRMED,
    payload : userConfirmed
});